import React from "react";

import { DropDownList } from "../DropDownList";

import facesImg from "../../assets/faces-2.png";

import { Container, Content, ProcedureInfo, WhatsIcon } from "./styles";

export function FourthSection({ onOpenMessageModal }) {
	const faceProceduresList = [
		{
			title: "Botox",
			description: "A toxina botulínica, conhecida popularmente como botox, consiste na injeção de toxina em músculos específicos a fim de minimizar temporariamente linhas de expressão, rugas e os popularmente conhecidos como pés de galinha. A quantidade de injeções que deve ser aplicada depende de características pessoais de cada paciente. Os resultados são visíveis após alguns dias.",
		},
		{
			title: "Cosmiatria",
			description: "A Cosmiatria são procedimentos menos invasivos como aplicações de injetáveis, laser e peeling com fins estéticos aos pacientes. Dessa maneira é possível tratar e cuidar de pele e rosto e do corpo sem procedimentos mais agressivos.",
		},
		{
			title: "Estética facial",
			description: "Temos um conjunto de soluções para os tratamentos de rosto, sempre com o intuito de trazer uma melhor auto estima, beleza e claro, saúde.",
		},
		{
			title: "Peelings faciais",
			description: "O peeling facial estimula a renovação das células da pele por meio de uma descamação controlada e o uso de substâncias químicas para clarear manchas e cicatrizes de acne, além de atuar no tratamento de rugas e linhas de expressão.",
		},
	];

	return (
		<Container id='estetica'>
			<Content data-aos='fade-right' data-aos-offset='200' data-aos-delay='50' data-aos-duration='1000' data-aos-easing='ease-in-out'>
				<picture>
					<img src={facesImg} alt='Tratamentos estéticos para o rosto' />
				</picture>

				<ProcedureInfo>
					<h2>Tratamentos estéticos para o rosto</h2>

					<DropDownList infoList={faceProceduresList} titleColor={"#363636"} textColor={"#363636"} />
				</ProcedureInfo>
			</Content>
		</Container>
	);
}
