import React from "react";

import drPinImg from "../../assets/dr-pin.png";

import Leaflet from "leaflet";

import "leaflet/dist/leaflet.css";

import { MapContainer, TileLayer, Marker } from "react-leaflet";

import { Container } from "./styles";

export function SeventhSection() {
  const mapIcon = Leaflet.icon({
    iconUrl: drPinImg,
    iconSize: [120, 60],
    iconAnchor: [40, 60],
  });

  return (
    <Container>
      <MapContainer
        center={[-25.4444864, -49.2854256]}
        zoom={15}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker icon={mapIcon} position={[-25.4444864, -49.2854256]} />
      </MapContainer>
    </Container>
  );
}
