import React from "react";

import { Container } from "./styles";

export function Footer() {
  return (
    <Container>
      <p>
        Copyright © Dr. Dayson Luiz Nicolau dos Santos. Todos os Direitos
        Reservados.
      </p>
    </Container>
  );
}
