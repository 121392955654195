import styled from "styled-components";

export const Container = styled.div`
  background-color: transparent;
  width: 100%;
  max-width: 28rem;

  .MuiAccordion-root:before {
    background-color: transparent;
  }

  div.MuiAccordion-rounded {
    border: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    p,
    span {
      color: ${(props) => props.titleColor};
      font-weight: bold;
    }

    span.description {
      color: ${(props) => props.textColor};
      line-height: 1.5rem;
      font-weight: normal;
    }
  }
`;

export const InfoList = styled.div``;
