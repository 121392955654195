import styled from "styled-components";
import { FiArrowRight } from "react-icons/fi";
import bgImg from "../../assets/bg-gradient.png";
import bgMobileImg from "../../assets/bg-gradient-mbl.png";

export const Container = styled.section`
  width: 100%;
  padding-top: 6.25rem;
  background-image: url(${bgMobileImg});
  background-repeat: no-repeat;
  background-size: 100% 70%;

  @media (min-width: 1024px) {
    background-image: url(${bgImg});
    background-repeat: no-repeat;
    background-size: 100% 70%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const ContentTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 1024px) {
    align-items: center;
    padding-top: 2rem;
  }

  div.text-box {
    padding-left: 2rem;

    @media (min-width: 1024px) {
      padding-left: 10rem;
    }

    h1 {
      margin-top: 1.875rem;
      color: var(--cyan-400);
      font-size: 1.625rem;
      line-height: 2rem;
      width: auto;

      @media (min-width: 550px) {
        font-size: 2.5rem;
        line-height: 3rem;
      }

      @media (min-width: 1024px) {
        width: 27rem;
        font-size: 2.625rem;
        line-height: 3.1875rem;
      }
    }

    p {
      font-size: 1.5rem;
      margin-top: 1.625rem;
      color: var(--gray-800);
    }

    button.dkt-button {
      display: none;

      margin-top: 2rem;
      text-align: center;
      width: 100%;
      max-width: 28rem;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.875rem;
      background-color: var(--purple-400);
      color: var(--white);
      border-radius: 200px;
      transition: all 200ms ease;

      &:hover {
        filter: brightness(0.9);
      }

      @media (min-width: 1024px) {
        display: block;
        height: 4rem;
      }
    }
  }

  img.dkt-hero {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      height: auto;
    }
  }

  div.image-box {
    width: 100%;

    img {
      margin-top: 3rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

export const ScheduleBox = styled.div`
  width: 100%;
  text-align: center;

  @media (min-width: 1024px) {
    display: none;
  }

  button {
    text-align: center;
    width: 80%;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.875rem;
    background-color: var(--purple-400);
    color: var(--white);
    padding: 1rem 2rem;
    border-radius: 200px;
    transition: all 200ms ease;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const DrSkills = styled.div`
  width: 100%;
  margin-top: -3rem;
  padding: 6rem 0;
  background-color: var(--gray-800);
  color: var(--white);

  @media (min-width: 1024px) {
    margin-top: 0rem;
  }

  div {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }

    ul {
      width: 90%;

      @media (min-width: 1024px) {
        width: auto;
      }

      li {
        line-height: 1.75rem;
      }

      li + li {
        margin-top: 1rem;
      }
    }

    button {
      margin-top: 2rem;

      display: flex;
      align-items: center;

      font-size: 1.1rem;
      font-weight: bold;
      padding: 1rem;

      background-color: transparent;
      color: var(--cyan-400);

      border-radius: 200px;
      border: 3px solid #00c1d3;

      transition: all 200ms ease;

      &:hover {
        filter: brightness(0.9);
      }

      @media (min-width: 1024px) {
        margin-top: 0;
        width: 25.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const ArrowIcon = styled(FiArrowRight)`
  font-size: 1.5rem;
  color: var(--cyan-400);
  margin-left: 1rem;
`;
