import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --cyan-400: #00C1D3;
    --purple-400: #2C41FF;
    --gray-800: #363636;
    --white: #ffffff;
  }


  html {
    @media (max-width: 1080px) {
      font-size: 93.75%; 
      
    }

    @media (max-width: 720px) {
      font-size: 87.5%; 
      -webkit-appearance:none;
    }
  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  input, button {
    border: none;
    outline: none;
  }

  button {
    cursor: pointer;
    border: none;
  }

  a {
    color: inherit;
  }

  .react-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;

  }

  .react-modal-content {
    width: 90%;
    height: 80%;
    max-width: 1200px;
    padding: 0rem;
    position: relative;
    border-radius: 0.25rem;
  }

  .react-modal-content-message {
    width: 90%;
    max-width: 1200px;
    padding: 0rem;
    position: relative;
    border-radius: 20px;
  }

  
  .close-react-modal {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 200ms;
    z-index: 10;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export default GlobalStyle;
