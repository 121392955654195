import styled from "styled-components";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

export const Container = styled.section`
  width: 100%;
  background-color: var(--gray-800);
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
  max-width: 1200px;
`;

export const ClinicInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding-bottom: 4rem;
  border-bottom: 1px solid #757575;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const TextInformation = styled.div`
  width: 100%;

  h2 {
    font-size: 1.875rem;
    font-weight: 800;
    color: var(--cyan-400);
    margin-bottom: 2rem;
  }

  p {
    color: var(--white);
    line-height: 1.5rem;

    & + p {
      margin-top: 1rem;
    }
  }
`;

export const ClinicImages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  div.current-image {
    width: 100%;
    position: relative;

    @media (min-width: 1024px) {
      height: 20rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  div.images {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;

    img {
      width: 100%;
      object-fit: cover;
      cursor: pointer;

      @media (min-width: 1024px) {
        height: 5rem;
      }

      &.current {
        border: 2px solid var(--white);
      }
    }
  }
`;

export const ImageOptions = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;

  display: flex;
  align-items: center;
  gap: 1rem;

  div {
    width: 0.75rem;
    height: 0.75rem;
    background-color: transparent;
    border: 1px solid var(--white);
    border-radius: 50%;
    cursor: pointer;

    &.selected {
      background-color: var(--white);
    }
  }
`;

export const ContactInformation = styled.div`
  width: 100%;
  padding: 4rem 0;

  @media (min-width: 1024px) {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
  }

  ul {
    list-style: none;
    margin-bottom: 3rem;
    order: 1;
  }

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
    }

    div {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: var(--cyan-400);

      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }

    p {
      color: var(--white);
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  img {
    width: 100%;
    max-width: 25rem;
    object-fit: cover;
  }
`;

export const ContactButton = styled.button`
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 26rem;
  height: 4.75rem;
  border-radius: 200px;
  background-color: var(--cyan-400);
  color: var(--white);
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.875rem;
  transition: all 200ms ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const FaceIcon = styled(FaFacebookF)`
  color: var(--white);
  font-size: 1.5rem;
`;

export const InstaIcon = styled(FaInstagram)`
  color: var(--white);
  font-size: 1.5rem;
`;
