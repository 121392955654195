import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  height: 8.125rem;
  background-color: var(--cyan-400);

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    width: 80%;
    max-width: 1200px;
    text-align: center;
    font-size: 1.25rem;
    color: #363636;
  }
`;
