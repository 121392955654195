import { useState } from "react";

import Modal from "react-modal";
import InputMask from "react-input-mask";
import ReactWhatsapp from "react-whatsapp";

import drPinImg from "../../assets/dr-pin.png";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

import { FiX } from "react-icons/fi";

import { Container, MessageForm, MapWrapper } from "./styles";

export function MessageModal({ isOpen, onRequestClose }) {
  const mapIcon = Leaflet.icon({
    iconUrl: drPinImg,
    iconSize: [120, 60],
    iconAnchor: [40, 60],
  });

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-message"
    >
      <Container>
        <MessageForm>
          <h3>Agende a sua consulta</h3>

          <p>Preencha os dados abaixo que entraremos em contato.</p>

          <form action="">
            <div className="box">
              <label htmlFor="name">Nome:</label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
            </div>

            <div className="box">
              <label htmlFor="phone">Telefone:</label>
              <InputMask
                id="phone"
                mask="(99) 99999-9999"
                name="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div className="box">
              <label htmlFor="message">Mensagem:</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
                name="message"
                id="message"
                cols="30"
                rows="5"
              ></textarea>
            </div>

            <ReactWhatsapp
              className="message"
              number="55 (41) 99208-8383"
              message={`Nome: ${name}\n\nTelefone: ${phoneNumber}\n\nMensagem: ${message}`}
            >
              Enviar mensagem
            </ReactWhatsapp>
          </form>
        </MessageForm>
        <MapWrapper>
          <MapContainer
            center={[-25.4444864, -49.2854256]}
            zoom={15}
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <Marker icon={mapIcon} position={[-25.4444864, -49.2854256]} />
          </MapContainer>
        </MapWrapper>
      </Container>

      <button
        type="button"
        onClick={onRequestClose}
        className="close-react-modal"
      >
        <FiX color="#000" fontSize={24} />
      </button>
    </Modal>
  );
}
