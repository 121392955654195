import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";
import bgImgDesktop from "../../assets/fourth-section-bg-dkt.png";

export const Container = styled.section`
  width: 100%;
  background-color: #f6f6f6;

  @media (min-width: 1024px) {
    background-image: url(${bgImgDesktop});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
  max-width: 1200px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    align-items: flex-start;
  }

  picture {
    width: 100%;
    height: fit-content;
    max-width: 750px;

    img {
      width: 100%;
    }

    @media (min-width: 1024px) {
      width: 100%;
      order: 2;
    }
  }
`;

export const ProcedureInfo = styled.div`
  h2 {
    color: var(--cyan-400);
    font-size: 1.625rem;
    line-height: 2rem;
    margin: 2rem 0;

    @media (min-width: 1024px) {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
  }

  button {
    display: block;
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    margin-top: 2rem;
    color: var(--white);
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    height: 4.75rem;
    background-color: var(--cyan-400);
    border-radius: 200px;
    transition: all 200ms ease-in;

    &:hover {
      filter: brightness(0.8);
    }

    @media (min-width: 1024px) {
      margin: 2rem 0;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const WhatsIcon = styled(FaWhatsapp)`
  color: var(--white);
  font-size: 1.5rem;
  margin-left: 1rem;
`;
