import React from "react";

import { DropDownList } from "../DropDownList";

import facesImg from "../../assets/faces.png";

import { Container, Content, ProcedureInfo } from "./styles";

export function SecondSection({ onOpenMessageModal }) {
  const faceProceduresList = [
    {
      title: "Cicatrizes, lesões de pele, nervos",
      description:
        "O objetivo é melhorar, se possível, o aspecto de cicatrizes preexistentes e/ou remover lesões da pele. Toda cicatriz é resultado de um grande número de fatores como cor e tipo de pele, idade, relação e reação pessoal às rugas, às articulações e várias outras particularidades. Isso porque, cada local do corpo humano possui pele ou características diferentes, sendo portanto, inconclusivas quaisquer comparações entre cicatrizes em locais diferentes ou no mesmo local e principalmente  em diferentes pessoas.",
    },
    {
      title: "Mentoplastia (cirurgia do queixo)",
      description:
        "A Mentoplastia destina-se a diminuir ou aumentar o queixo (mento). Este tem seu posicionamento ideal na face, obedecendo a certos limites, o que confere harmonia ao rosto. Contudo, não é incomum que o queixo apresente uma posição mais avançada ou mais retraída, comprometendo, assim, a beleza e a harmonia do rosto. A Mentoplastia mais comum é aquela que visa corrigir o retro-posicionamento (queixo retraído), e isso é feito por meio da inclusão de uma peça de silicone ou utilizando osso do(a) próprio(a) paciente.",
    },
    {
      title: "Otoplastia (cirurgia das orelhas)",
      description:
        "Objetivo da cirurgia é  modificar a posição da orelha com o intuito de diminuir o aspecto da denominada “orelha de abano”, contribuindo assim para o bem-estar psicossocial do indivíduo e, principalmente, das crianças em idade escolar, devido aos comentários e/ou zombaria dos colegas. Todavia, nada impede que tal correção se faça em outras fases da vida. Consta de uma correção da cartilagem auricular (da orelha) que não adquire a forma normal durante o período embrionário (na gestação)",
    },
    {
      title: "Rinoplastia (cirurgia do nariz)",
      description:
        "A Rinoplastia é uma das mais antigas e tradicionais cirurgias no âmbito da cirurgia plástica, pois os cirurgiões indianos já a praticavam há dois mil anos. É um tipo de cirurgia muito notada por seus resultados, devido ao fato de modificar sensivelmente a face do(a) paciente. Não é possível prever, com absoluta exatidão, e nos mínimos detalhes, o formato final de um nariz após uma Rinoplastia. Mesmo tendo-se objetivos a atingir, não é possível prever o quanto destes objetivos serão alcançados. E isto se deve ao fato de que cada paciente tem características que lhe são próprias, tais como a espessura da pele e das cartilagens do nariz, elementos que podem interferir no resultado final.",
    },
    {
      title: "Ritidoplastia (lifting facial)",
      description:
        "Também chamada CIRURGIA DO REJUVENESCIMENTO FACIAL , o objetivo da cirurgia conhecida como “face lift” ou, também, “lifting de face” é amenizar os sinais do envelhecimento facial, pois sabe-se ser impossível voltar a ter o mesmo aspecto facial dos anos de juventude. Ou seja, não será possível devolver-lhe a face que você tinha aos 18, 20, 30 ou 40 anos. Entretanto, será possível atenuar as marcas da maturidade, conferindo-lhe um aspecto mais agradável e mais descansado.",
    },
  ];

  return (
    <Container id="cirurgias">
      <Content
        data-aos="fade-right"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <picture>
          <img src={facesImg} alt="Procedimentos cirúrgicos para o rosto" />
        </picture>

        <ProcedureInfo>
          <h2>Procedimentos cirúrgicos para o rosto</h2>

          <DropDownList
            infoList={faceProceduresList}
            titleColor={"#FFFFFF"}
            textColor={"#363636"}
          />

          <button onClick={onOpenMessageModal}>Tenho interesse</button>
        </ProcedureInfo>
      </Content>
    </Container>
  );
}
