import { useState } from "react";

import headerLogoImg from "../../assets/header-logo.png";
import menuImg from "../../assets/menu.png";
import whatsappImg from "../../assets/whatsapp-icon.png";
import phoneImg from "../../assets/phone-icon.png";
import { FloatingWhatsApp } from "react-floating-whatsapp";

import { Container, Content, NavLinks, MobileLinks, LinkIcon } from "./styles";

export function Header({ onOpenMessageModal }) {
	const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

	return (
		<Container>
			<Content>
				<div className='name'>
					<a href='#home'>
						<img src={headerLogoImg} alt='Dr Dayson' />
					</a>
				</div>

				<NavLinks>
					<div className='page-links'>
						<a href='#cirurgias'>Cirurgias</a>
						<a href='#estetica'>Estética</a>
						<a href='#clinica'>Clínica</a>
						<a href='#contato'>Contato</a>
					</div>

					<div className='contact-links'>
						<button>
							<img src={whatsappImg} alt='Whatsapp' />
							<a href='https://encurtador.com.br/pzLY5' target='_blank' rel='noreferrer'>
								<span>
									<strong>41 99208 8383</strong>
								</span>
							</a>
						</button>

						<button>
							<img src={phoneImg} alt='Telefone' />
							<span>41 3342 2485</span>
						</button>

						<a href='https://encurtador.com.br/pzLY5' className='send-message'>
							Envie sua mensagem
						</a>
					</div>
				</NavLinks>

				<button className='mobile-menu' onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}>
					<img src={menuImg} alt='Menu mobile' />
				</button>
			</Content>

			<MobileLinks active={isMobileMenuActive}>
				<ul>
					<li>
						<a href='#cirurgias'>
							<LinkIcon />
							Cirurgias
						</a>
					</li>
					<li>
						<a href='#estetica'>
							<LinkIcon />
							Estética
						</a>
					</li>
					<li>
						<a href='#clinica'>
							<LinkIcon />
							Clínica
						</a>
					</li>
					<li>
						<a href='#contato'>
							<LinkIcon />
							Contato
						</a>
					</li>
				</ul>
			</MobileLinks>
			<FloatingWhatsApp avatar={"https://www.drdayson.com.br/favicon.jpeg"} statusMessage={"Online"} phoneNumber={"+5541992088383"} accountName={"Dr. Dayson"} chatMessage={"Olá tudo bem? Em que posso ajudar?"} placeholder={"Escreva sua mensagem..."} />
		</Container>
	);
}
