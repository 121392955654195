import React from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { Container } from "./styles";

export function DropDownList({ infoList, titleColor, textColor }) {
  return (
    <Container titleColor={titleColor} textColor={textColor}>
      {infoList.map((info, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p>{info["title"]}</p>
          </AccordionSummary>
          <AccordionDetails>
            <span className="description">{info["description"]}</span>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}
