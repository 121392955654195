import { useState } from "react";
import { Header } from "./components/Header";
import { FirstSection } from "./components/FirstSection";
import { SecondSection } from "./components/SecondSection";
import { ThirdSection } from "./components/ThirdSection";
import { FourthSection } from "./components/FourthSection";
import { FifthSection } from "./components/FifthSection";
import { SixthSection } from "./components/SixthSection";
import { SeventhSection } from "./components/SeventhSection";
import { Footer } from "./components/Footer";

import AOS from "aos";
import "aos/dist/aos.css";

import { ResumeModal } from "./components/ResumeModal";
import { MessageModal } from "./components/MessageModal";

import GlobalStyles from "./styles/global";

import Modal from "react-modal";

Modal.setAppElement("#root");
AOS.init({
  once: true,
});

export default function App() {
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);

  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  function handleOpenResumeModal() {
    setIsResumeModalOpen(true);
  }

  function handleCloseResumeModal() {
    setIsResumeModalOpen(false);
  }

  function handleOpenMessageModal() {
    setIsMessageModalOpen(true);
  }

  function handleCloseMessageModal() {
    setIsMessageModalOpen(false);
  }

  return (
    <>
      <Header onOpenMessageModal={handleOpenMessageModal} />
      <FirstSection
        onOpenResumeModal={handleOpenResumeModal}
        onOpenMessageModal={handleOpenMessageModal}
      />
      <SecondSection onOpenMessageModal={handleOpenMessageModal} />
      <ThirdSection onOpenMessageModal={handleOpenMessageModal} />
      <FourthSection onOpenMessageModal={handleOpenMessageModal} />
      <FifthSection onOpenMessageModal={handleOpenMessageModal} />
      <SixthSection onOpenMessageModal={handleOpenMessageModal} />
      <SeventhSection />
      <Footer />
      <ResumeModal
        isOpen={isResumeModalOpen}
        onRequestClose={handleCloseResumeModal}
      />
      <MessageModal
        isOpen={isMessageModalOpen}
        onRequestClose={handleCloseMessageModal}
      />

      <GlobalStyles />
    </>
  );
}
