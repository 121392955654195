import React from "react";

import { DropDownList } from "../DropDownList";

import bodiesImg from "../../assets/bodies.png";

import { Container, Content, ProcedureInfo } from "./styles";

export function ThirdSection({ onOpenMessageModal }) {
  const bodyProceduresList = [
    {
      title: "Abdominoplastica (cirurgia do abdômen)",
      description:
        "A Dermolipectomia Abdominal é uma cirurgia que se destina a retirar determinada quantidade de pele e (de) gordura do abdome, da parte inferior do abdômen, geralmente chamado “abdômen em avental” que seria a sobra de pele abaixo do umbigo que cai sobre a região do pube com a finalidade mais higiênica.  Já a Abdominoplastia  é um procedimento mais amplo, que se estende até a região do abdômen superior (acima do umbigo), para a correção da flacidez de todo o abdômen, inclusive com a correção da musculatura abdominal e tem a finalidade da manutenção da proporcionalidade e da harmonia do corpo como um todo.",
    },
    {
      title: "Braquioplastias (cirurgia dos braços)",
      description:
        "É um procedimento indicado para tratar o excesso de gordura localizada, flacidez ou sobra de pele da região do braço, principalmente na região posterior do braço,  também conhecido como 'músculo do tchau' apesar de não ser tratado o músculo ( no caso o tríceps) . Pode ser feita com ou sem lipoaspiração da região",
    },
    {
      title: "Cruroplastia (cirurgia das coxas)",
      description:
        "A cruroplastia, também conhecida como lifting de coxas , lifting crural, dermolipectomia crural ou dermolipectomia das coxas,  é a cirurgia que possibilita remodelar o contorno das coxas e tratar a flacidez da face interna delas, reduzindo o excesso de pele e  gordura localizada nessa área.",
    },
    {
      title: "Ginecomastia (plástica mamária masculina)",
      description:
        "A cirurgia de Ginecomastia se destina a retirar o excesso de gordura e/ou de glândulas da região peitoral masculina. Quando o volume excessivo local for exclusivamente de gordura, esta será retirada por lipoaspiração, restando uma cicatriz de aproximadamente 0,5 cm. Quando houver glândula mamária ocasionando a deformidade, a cicatriz deixada ficará na borda aréola. Em caso de grande volume local poderá ocorrer sobra de pele. Entretanto esta pele, com o passar do tempo, se reacomodará sobre a região ou ser retirada no mesmo ato cirúrgico caso haja pele em excesso, nestes casos, com uma cicatriz maior e mais visível.",
    },
    {
      title: "Gluteoplastia (cirurgia das nádegas)",
      description:
        "O objetivo deste tipo de cirurgia é o de aumentar o volume na região glútea com a inclusão de implantes de silicone (próteses). Assim, a cirurgia de aumento dos glúteos lhe proporcionará nádegas maiores, mas não vai lhe beneficiar com a elevação das nádegas quando estas já estiverem caídas pela flacidez da pele e pelos músculos locais. Neste caso será necessário uma cirurgia de Lifting glúteo (suspensão das nádegas)",
    },
    {
      title: "Lipoaspiração e Lipoescultura",
      description:
        "Lipoaspiração é o nome dado a uma técnica cujo objetivo é a retirada parcial das gorduras localizadas, as quais modificam o contorno corporal. Por sua vez, a Lipoenxertia consiste em injetar a própria gordura retirada, com a finalidade de modelar partes do corpo ou preencher depressões. Tanto a Lipoaspiração quanto a Lipoenxertia não são tratamentos para a obesidade, bem como não substituem a necessidade de emagrecimento ou de praticar exercícios físicos.",
    },
    {
      title: "Mamoplastias (cirurgias das mamas)",
      description: `As cirurgias das mamas podem ser:

        Mamoplastia de Aumento: Esta é uma das cirurgias plásticas bastante procuradas pelas pacientes que estão descontentes com o volume de suas mamas, sendo também indicada para melhorar o aspecto estético das mesmas. A cirurgia de aumento mamário com a inclusão de implantes mamários de silicone (próteses) não só proporcionará mamas maiores, mas também vai elevar a altura das aréolas e dos mamilos, quando estes estiverem caídos sem flacidez exagerada.

        Mastopexia : nestes casos a cirurgia é indicada onde há mamas com quedas moderadas a grandes, que não serão elevadas apenas com o uso de implantes. Será necessário a retirada de pele concomitante. Há, em certos casos, a possibilidade de, dependendo do volume mamário, fazer a pexia (levantamento) das mamas sem a inclusão dos implantes mamários (próteses) mas haverá uma diferença de resultado no que tange a firmeza das mamas.
        
        Mamoplastia Redutora: como o próprio nome diz, é a cirurgia para redução do volume mamário em mamas grandes, chamadas de hipertróficas ou até de gigantomastia de acordo com o volume acima da média.`,
    },
    {
      title: "Ninfoplastia (cirurgia genital)",
      description:
        "Ninfoplastia ou Labioplastia é uma cirurgia plástica que consiste na remoção de pele dos lábios vaginais. É utilizada geralmente para correção estética dos lábios vaginais quando estes crescem de forma anormal ou quando um cresce mais que o outro.  Consiste na redução dos pequenos lábios vaginais em mulheres que têm hipertrofia dessa área.",
    },
    {
      title: "Panturrilha (cirurgia da “barriga da perna”)",
      description:
        "A cirurgia de aumento da panturrilha lhe proporcionará panturrilhas mais grossas, mas somente na sua porção interna. A escolha do tamanho desejado não poderá ser definida com exatidão antes da cirurgia: o seu médico experimentará moldes de tamanhos de próteses durante o ato cirúrgico, mas a escolha final será baseada nas suas medidas e, principalmente, nas condições de acomodação e elasticidade de sua pele local.",
    },
  ];

  return (
    <Container>
      <Content
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <picture>
          <img src={bodiesImg} alt="Procedimentos cirúrgicos para o corpo" />
        </picture>

        <ProcedureInfo>
          <h2>Procedimentos cirúrgicos para o corpo</h2>

          <DropDownList
            infoList={bodyProceduresList}
            titleColor={"#363636"}
            textColor={"#363636"}
          />

          <button onClick={onOpenMessageModal}>
            Para saber mais agende sua consulta
          </button>
        </ProcedureInfo>
      </Content>
    </Container>
  );
}
