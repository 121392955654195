import React from "react";

import drDaysonMobileImg from "../../assets/dr-dayson-mbl.png";
import drDaysonDesktopImg from "../../assets/dr-dayson-dkt.png";

import {
  Container,
  Content,
  ContentTitle,
  ScheduleBox,
  DrSkills,
  ArrowIcon,
} from "./styles";

export function FirstSection({ onOpenResumeModal, onOpenMessageModal }) {
  return (
    <Container id="home">
      <Content
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <ContentTitle>
          <div className="text-box">
            <h1>
              Titular Especialista da Sociedade Brasileira de Cirurgia Plástica
            </h1>
            <p>CRM-PR 7630 / RQE 4695</p>

            <button className="dkt-button" onClick={onOpenMessageModal}>
              Quero agendar a minha consulta
            </button>
          </div>

          <img className="dkt-hero" src={drDaysonDesktopImg} alt="Dr Dayson" />

          <div className="image-box">
            <img src={drDaysonMobileImg} alt="Dr Dayson" />
          </div>
        </ContentTitle>

        <ScheduleBox>
          <button onClick={onOpenMessageModal}>
            Quero agendar a minha consulta
          </button>
        </ScheduleBox>
      </Content>

      <DrSkills>
        <div>
          <ul>
            <li>
              Membro Titular e Especialista da Sociedade Brasileira de Cirurgia
              Plástica.
            </li>
            <li>
              Sócio Proprietário da Clini Vi-Da-Clínica de Estética e
              Reparadora.
            </li>
            <li>Formado em Medicina pela UFPR em 1981.</li>
          </ul>

          <button onClick={onOpenResumeModal}>
            Confira o currículo completo
            <ArrowIcon />
          </button>
        </div>
      </DrSkills>
    </Container>
  );
}
