import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white);
  border-radius: 20px;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  img {
    display: none;

    @media (min-width: 1024px) {
      display: block;
      width: 100%;
    }
  }
`;

export const MessageForm = styled.div`
  padding: 2rem;
  background-color: var(--cyan-400);
  border-radius: 20px;

  @media (min-width: 1024px) {
    padding: 3rem;
    border-radius: 20px 0 0 20px;
  }

  h3 {
    color: var(--white);
    font-size: 1.25rem;
    margin-bottom: 2rem;

    @media (min-width: 1024px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  div.box {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

    label {
      margin-bottom: 0.25rem;
    }

    input,
    button {
      height: 3.125rem;
      border-radius: 10px;
    }

    textarea {
      border-radius: 10px;
    }

    input,
    textarea {
      padding: 1rem;
      font-size: 1.25rem;
    }
  }

  button.message {
    background-color: var(--purple-400);
    color: var(--white);
    font-weight: bold;
    padding: 1rem 2rem;
    border-radius: 20px;

    @media (min-width: 1024px) {
      font-size: 1.5rem;
      font-weight: bold;
      border-radius: 200px;
    }
  }
`;

export const MapWrapper = styled.div`
  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    border-radius: 0 20px 20px 0;
  }
`;
