import styled from "styled-components";
import { FiChevronRight } from "react-icons/fi";

export const Container = styled.header`
  width: 100%;
  height: 6.25rem;

  position: fixed;
  top: 0;
  left: 0;

  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;

  background-color: white;

  @media (min-width: 1024px) {
    background-color: #eaeaea;
    padding: 0;
  }

  div.name {
    height: 100%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 16rem;
    }

    @media (min-width: 1024px) {
      width: 18.75rem;
      text-align: center;
    }
  }

  button.mobile-menu {
    background: transparent;

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

export const NavLinks = styled.nav`
  display: none;

  @media (min-width: 1024px) {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1500px) {
      justify-content: space-evenly;
    }
  }

  div.page-links {
    @media (max-width: 1180px) {
      display: none;
    }

    a {
      text-decoration: none;
      color: var(--gray-800);
      margin-right: 4rem;
      font-size: 1.25rem;
      transition: all 200ms;

      @media (max-width: 1500px) {
        font-size: 1rem;
        margin-right: 1rem;
      }

      &:hover {
        color: var(--cyan-400);
        font-weight: bold;
      }
    }
  }

  div.contact-links {
    display: flex;

    button {
      display: flex;
      align-items: center;
      background: transparent;
      margin-right: 2rem;
      font-size: 1.25rem;
      color: var(--gray-800);
      transition: all 200ms;

      @media (max-width: 1450px) {
        font-size: 1rem;
        margin-right: 1rem;
      }

      &:hover {
        filter: brightness(0.9);
      }

      img {
        margin-right: 1rem;
      }
    }

    button.send-message {
      color: var(--white);
      font-size: 1.375rem;
      background: var(--purple-400);
      padding: 1rem 2rem;
      border-radius: 50px;
      transition: all 200ms;

      @media (max-width: 1600px) {
        font-size: 1rem;
        padding: 1rem;
      }

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
`;

export const MobileLinks = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};

  background-color: white;

  ul {
    padding-bottom: 0.5rem;
  }

  ul li {
    padding: 0.5rem 2rem;

    a {
      color: var(--cyan-400);
      text-decoration: none;
    }
  }
`;

export const LinkIcon = styled(FiChevronRight)`
  color: var(--cyan-400);
  margin-right: 0.5rem;
`;
