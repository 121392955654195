import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  background-color: var(--cyan-400);
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
  max-width: 1200px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    align-items: flex-start;
  }

  picture {
    width: 100%;
    max-width: 750px;
    height: fit-content;

    img {
      width: 100%;
    }

    @media (min-width: 1024px) {
      width: 100%;
      order: 2;
    }
  }
`;

export const ProcedureInfo = styled.div`
  h2 {
    color: var(--gray-800);
    font-size: 1.625rem;
    line-height: 2rem;
    margin: 2rem 0;

    @media (min-width: 1024px) {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
  }

  button {
    display: block;
    width: 100%;
    max-width: 28rem;
    margin: 0 auto;
    margin-top: 2rem;
    color: var(--white);
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    height: 4.75rem;
    background-color: var(--purple-400);
    border-radius: 200px;
    transition: all 200ms ease-in;

    &:hover {
      filter: brightness(0.8);
    }

    @media (min-width: 1024px) {
      margin: 2rem 0;
      margin-right: auto;
    }
  }
`;
