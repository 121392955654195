import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 30rem;

  @media (min-width: 1024px) {
    height: 38rem;
  }
`;
