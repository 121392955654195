import React from "react";

import { DropDownList } from "../DropDownList";

import bodiesImg from "../../assets/bodies-2.png";

import { Container, Content, ProcedureInfo } from "./styles";

export function FifthSection({ onOpenMessageModal }) {
  const bodyProceduresList = [
    {
      title: "Celulite",
      description:
        "Celulite é o nome popular da lipodistrofia ginoide, que nada mais é que o depósito de gordura sob a pele. Vale lembrar que além dos tratamentos é preciso reduzir o consumo de açúcar e gorduras, tomar muita água e praticar exercícios físicos, que ajudam na redução de gordura corporal, ou musculação, que auxilia na firmeza da pele.",
    },
    {
      title: "Drenagem Linfática",
      description:
        "Procedimento indicado para tratar qualquer grau de celulite. A drenagem linfática basicamente é a ação de realizar uma massagem ns regiões a serem tratadas de forma a eliminar o excesso de líquido acumulado e pequenos nódulos de gordura.",
    },
    {
      title: "Radiofrequênca",
      description:
        "Tratamento que estimula a produção de colágeno na pele com a ajuda de um aparelho com radiação eletromagnética de alta frequência agitando as moléculas de água existentes no corpo. Além de combater a celulite, esse procedimento combate também a flacidez.",
    },
    {
      title: "Estrias",
      description:
        "As estrias são cicatrizes formadas devido a um rompimento de colágeno na superfície da pele. São vários os tratamentos indicados como esfoliação, peelings, ácidos, laser e algumas vezes procedimentos cirúrgicos.",
    },
  ];

  return (
    <Container>
      <Content
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <picture>
          <img src={bodiesImg} alt="Tratamentos estéticos para o corpo" />
        </picture>

        <ProcedureInfo>
          <h2>Tratamentos estéticos para o corpo</h2>

          <DropDownList
            infoList={bodyProceduresList}
            titleColor={"#363636"}
            textColor={"#363636"}
          />

          <button onClick={onOpenMessageModal}>
            Será um prazer atendê-lo(a)
          </button>
        </ProcedureInfo>
      </Content>
    </Container>
  );
}
