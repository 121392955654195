import React from "react";
import Modal from "react-modal";

import { FiX } from "react-icons/fi";

import { Container } from "./styles";

export function ResumeModal({ isOpen, onRequestClose }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <Container>
        <h3>Dr Dayson Luiz Nicolau dos Santos</h3>

        <div className="info">
          <span>Resumo</span>
          <ul>
            <li>
              Médico pela Universidade Federal do Paraná, graduado em junho de
              1981.
            </li>
            <li>
              Licenciado em Educação Física pela Universidade Federal do Paraná,
              em janeiro de 1981.
            </li>
            <li>
              Especialista em Cirurgia Plástica Estética e Reconstrutiva pela
              Sociedade Brasileira de Cirurgia Plástica (SBCP).
            </li>
            <li>
              Membro Titular da Sociedade Brasileira de Cirurgia Plástica
              (SBCP).
            </li>
          </ul>
        </div>

        <div className="info">
          <span>CV completo</span>
          <ul>
            <li>
              Médico Cirurgião Plástico e Chefe do Serviço de Cirurgia Plástica
              do Hospital Universitário Cajuru da Pontifícia Universidade
              Católica do Paraná (HUC). 
            </li>
            <li>
              Médico Cirurgião Plástico do Pronto Socorro Municipal do Hospital
              Universitário Cajurú da Pontifícia Universidade Católica do
              Paraná.
            </li>
            <li>
              Médico Cirurgião Plástico do Instituto de Neurocirurgia de
              Curitiba (INC).
            </li>
            <li>
              Médico da ASSOMA ( Associação dos Meninos de Curitiba) até 2007.
            </li>
            <li>
              Médico Cirurgião Plástico do Hospital Vita Curitiba e Vita Batel.
            </li>
            <li>
              Médico Cirurgião Plástico do Hospital Santa Casa de Curitiba.
            </li>
            <li>
              Médico Cirurgião Plástico do Hospital Cardiológico Costantini.
            </li>
            <li>Médico Cirurgião Plástico do Hospital Pilar de Curitiba.</li>
            <li>Médico Cirurgião Plástico da Maternidade Curitiba.</li>
            <li>Médico Cirurgião Plástico do Onix Centro Hospitalar.</li>
            <li>Médico Cirurgião Plástico do Hospital Santa Cruz.</li>
            <li>Médico Cirurgião Plástico do Hospital Union.</li>
            <li>Médico Cirurgião Plástico do Hospital Dr. Muricy.</li>
            <li>
              Médico Cirurgião Plástico do Hospital e Maternidade Nossa Senhora
              do Rocio em Campo Largo (PR).
            </li>
            <li>
              Preceptor de Ensino da Residência Médica em Cirurgia Plástica do
              Hospital Universitário Cajuru e da Aliança Hospitalar da
              Pontifícia Universidade Católica de Curitiba. (Atual Chefe do
              Serviço desde 2013).
            </li>
            <li>
              Médico Perito pela SBPM (Sociedade Brasileira de Perícias
              Médicas).
            </li>
            <li>
              Membro do Corpo Docente do CIPLAST (Curso Informativo Anual de
              Cirurgia Plástica da Pontifícia Universidade Católica do Paraná),
              até 2014.
            </li>
          </ul>
        </div>
        <button
          type="button"
          onClick={onRequestClose}
          className="close-react-modal"
        >
          <FiX color="#000" fontSize={24} />
        </button>
      </Container>
    </Modal>
  );
}
