import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white);
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: 20px;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  h3 {
    color: var(--cyan-400);
    font-size: 1.75rem;
    font-weight: 800;
    margin-bottom: 2rem;

    @media (max-width: 820px) {
      font-size: 1.25rem;
    }
  }

  div.info {
    display: flex;
    align-items: flex-start;
    gap: 4rem;
    margin-bottom: 2rem;

    @media (max-width: 820px) {
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 4rem;
      padding-left: 1rem;
    }

    span {
      color: var(--cyan-400);
      font-weight: 800;
      width: 100px;

      @media (max-width: 820px) {
        font-size: 1.2rem;
      }
    }

    ul {
      width: 100%;
      color: var(--gray-800);

      li + li {
        margin-top: 5px;
      }
    }
  }
`;
