import { useState } from "react";

import room1Img from "../../assets/room-1.jpeg";
import room2Img from "../../assets/room-2.jpeg";
import room3Img from "../../assets/room-3.jpeg";

import pinImg from "../../assets/location-icon.png";
import clockImg from "../../assets/clock-icon.png";
import whatsappImg from "../../assets/whatsapp-icon.png";
import phoneImg from "../../assets/phone-icon.png";

import clinic from "../../assets/building.png";

import { Container, Content, ClinicInformation, TextInformation, ClinicImages, ImageOptions, ContactInformation, ContactButton, FaceIcon, InstaIcon } from "./styles";

export function SixthSection({ onOpenMessageModal }) {
	const redirectWhats = () => {
		window.location = "https://encurtador.com.br/pzLY5";
	};

	const images = [room1Img, room2Img, room3Img];

	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	function handleClickImage(imageIndex) {
		setCurrentImageIndex(imageIndex);
	}

	return (
		<Container id='clinica'>
			<Content data-aos='fade-up' data-aos-offset='200' data-aos-delay='50' data-aos-duration='1000' data-aos-easing='ease-in-out'>
				<ClinicInformation>
					<TextInformation>
						<h2>Clínica Dr. Dayson</h2>

						<p>Espaço elegante no bairro Batel, próximo a praça do Japão em Curitiba, PR.</p>

						<p>Priorizamos todas as regras de intervalos entre as consultas, mantendo o distanciamento social e práticas de saúde rigorosas.</p>

						<p>A sede mantém as janelas abertas para ampla ventilação, ar condicionado em todas as salas, estacionamento conveniado, portaria/segurança e secretária em horário comercial. </p>

						<p>Venha nos visitar.</p>
					</TextInformation>

					<ClinicImages>
						<div className='current-image'>
							<img src={images[currentImageIndex]} alt='Imagem da clínica' />

							<ImageOptions>
								<div onClick={() => handleClickImage(0)} className={currentImageIndex === 0 ? "selected" : ""}></div>
								<div onClick={() => handleClickImage(1)} className={currentImageIndex === 1 ? "selected" : ""}></div>
								<div onClick={() => handleClickImage(2)} className={currentImageIndex === 2 ? "selected" : ""}></div>
							</ImageOptions>
						</div>

						<div className='images'>
							<img onClick={() => handleClickImage(0)} className={currentImageIndex === 0 ? "current" : ""} src={room1Img} alt='Imagem da clínica' />
							<img onClick={() => handleClickImage(1)} className={currentImageIndex === 1 ? "current" : ""} src={room2Img} alt='Imagem da clínica' />
							<img onClick={() => handleClickImage(2)} className={currentImageIndex === 2 ? "current" : ""} src={room3Img} alt='Imagem da clínica' />
						</div>
					</ClinicImages>
				</ClinicInformation>

				<ContactInformation id='contato'>
					<ul>
						<li>
							<img src={pinImg} alt='Localização' />
							<p>Av. Sete de Setembro, 4866 Ed. Sete de Setembro, 3º andar, Batel. Curitiba, Paraná.</p>
						</li>

						<li>
							<img src={clockImg} alt='Horários' />
							<p>Segunda à Sexta-feira das 13:30 às 18:30h.</p>
						</li>

						<li>
							<a href='https://encurtador.com.br/pzLY5' style={{ display: "flex", alignItems: "center" }}>
								<img src={whatsappImg} alt='Whatsapp' />
								<p style={{ marginLeft: 15 }}> 41 99208 8383</p>
							</a>
						</li>

						<li>
							<img src={phoneImg} alt='Contato' />
							<p>41 3342 2485</p>
						</li>

						<li>
							<div>
								<InstaIcon />
							</div>
							<a target='_blank' rel='noreferrer' href='https://www.instagram.com/dr.dayson/'>
								<p>@dr.dayson</p>
							</a>
						</li>

						<li>
							<div>
								<FaceIcon />
							</div>
							<a target='_blank' rel='noreferrer' href='https://www.facebook.com/dr.dayson'>
								<p>dr.dayson</p>
							</a>
						</li>
					</ul>

					<img src={clinic} alt='Clínica' />
				</ContactInformation>

				<ContactButton onClick={() => redirectWhats()}>Agendar minha consulta</ContactButton>
			</Content>
		</Container>
	);
}
